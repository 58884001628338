<template>
  <div class="profile-container">
    <div class="profile-container-header">
      <span class="title">客户服务</span>
    </div>
    <div class="profile-container-uptab">
      <div class="profile-container-tab-left">
        <img src="../../../public/kefu.jpg" alt="" class="chat-image" />
      </div>
      <div class="profile-container-tab-right">
        <span>客服电话</span>
        <span style="font-size: 12px; color: #999">{{
          this.tableData.work_period_description
        }}</span>
        <span>电话号码：{{ this.tableData.phone }}</span>
      </div>
    </div>
    <div class="profile-container-tab">
      <div class="profile-container-tab-left">
        <img src="../../../public/weiixn.jpg" alt="" class="chat-image" />
      </div>
      <div class="profile-container-tab-right">
        <span>官方微信</span>
        <span style="font-size: 12px; color: #999"
          >识别二维码，关注官方微信</span
        >
        <img :src="this.tableData.wechat_url" alt="" class="chat-image" />
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/week";

export default {
  name: "contactUs",
  data() {
    return {
      active: 1,
      tableData: [],
      alarmLoading: false,
      // tableData: [
      //   {
      //     id: "JD123123",
      //     name: "一年级1班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123124",
      //     name: "一年级2班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123125",
      //     name: "一年级3班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      //   {
      //     id: "JD123126",
      //     name: "一年级4班",
      //     time: "2021-05-21 16:33:22",
      //     content: "初效滤网剩余1小时",
      //   },
      // ],
    };
  },
  methods: {
    getData() {
      this.alarmLoading = true;
      this.$http
        .findAllContactUsByCustomer()
        .then((res) => {
          this.tableData = res.data;
          this.alarmLoading = false;
        })
        .catch(() => {
          this.alarmLoading = false;
        });
    },
    dateFormat,
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
.profile-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  min-width: 999px;
  .profile-container-header {
    width: 100%;
    display: flex;
    height: 70px;
    padding-left: 40px;
    align-items: center;
    color: #333;
    //span {
    //  width: 90px;
    //  line-height: 50px;
    //  text-align: center;
    //  font-size: 14px;
    //  color: #333;
    //  cursor: pointer;
    //  border-bottom: 1px solid #eaeaea;
    //  &:nth-child(2) {
    //    border-left: 1px solid #eaeaea;
    //    border-right: 1px solid #eaeaea;
    //  }
    //  &:nth-last-child(1) {
    //    flex: 1;
    //  }
    //}
    //.active {
    //  //background-color: #999;
    //  border-bottom: none;
    //  position: relative;
    //  //border-top: 4px solid #01cfa5;
    //  &::before {
    //    content: "";
    //    width: 90px;
    //    height: 4px;
    //    background-color: #01cfa5;
    //    position: absolute;
    //    left: 0;
    //  }
    //}
  }
  .profile-container-uptab {
    margin-left: 50px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 150px;
    width: 600px;
  }
  .profile-container-tab {
    margin-left: 50px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 200px;
    width: 600px;
  }
  .profile-container-tab-left {
    //display: flex;
    //flex-direction: column;
    width: 130px;
  }
  .profile-container-tab-right {
    display: flex;
    flex-direction: column;
    span {
      margin-bottom: 10px;
    }
  }
  .chat-image {
    width: 100px;
    height: 100px;
  }
}
</style>
